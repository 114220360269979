import styledEmotion, { CreateStyled } from "@emotion/styled"

const theme = {
    breakpoints: ["40em", "52em", "64em"],
    colors: {
        black: "#000",
        white: "#fff",
        blue: "#08f",
    },
    fonts: {
        body: `"PT Sans", system-ui, sans-serif`,
        heading: "Actor, sans-serif",
        logoType: `"Press Start 2P", sans-serif`,
        monospace: `"Fira Code", Menlo, monospace`,
    },
    lineHeights: {
        body: 1.5,
        heading: 1.25,
    },
    shadows: {
        small: "0 0 4px rgba(0, 0, 0, .125)",
        large: "0 0 24px rgba(0, 0, 0, .125)",
    },
    variants: {},
    text: {},
    buttons: {
        primary: {
            color: "white",
            bg: "primary",
        },
    },
}

export type Theme = typeof theme

export const styled = styledEmotion as CreateStyled<Theme>

export default theme
