import React from "react"
import { css, Global } from "@emotion/core"
import { ThemeProvider } from "emotion-theming"

import theme from "@lib/theme"
import normalizedCss from "@lib/theme/normalize"

const Wrapper = (props: { element: React.ReactNode }) => (
    <>
        <Global
            styles={css`
                ${normalizedCss}

                body {
                    font-family: ${theme.fonts.body};
                }

                h1,
                h2,
                h3,
                h4,
                h5 {
                    font-family: ${theme.fonts.heading};
                }
            `}
        />
        <ThemeProvider theme={theme}>{props.element}</ThemeProvider>
    </>
)

export default Wrapper
